import type { default as RichTextArea, IRichTextAreaProps } from 'o365.vue.components.RichTextEditor.vue';
import { nextTick } from 'vue';
import fileUtils from "o365.modules.fileUtils.ts";

type AttachmentInfo = {
    PrimKey: string,
    FileName: string,
    Extension: string
    FileRef: string,
};

export default class RichTextAreaControl {
    private _getModel: () => string;
    private _setModel: (value: string) => void
    private _blurDebounce: number | null = null;

    textArea: RichTextArea | null = null;
    props: IRichTextAreaProps;

    editMode: boolean;
    isUploading = false;


    constructor(options: {
        props: IRichTextAreaProps,
        getModel: () => string,
        setModel: (value: string) => void,
    }) {
        this._getModel = options.getModel;
        this._setModel = options.setModel;
        this.props = options.props;

        this.editMode = this.props.editMode ?? false;
    }

    addHeading(pos?: number, posEnd?: number, model?: string) {
        const textAreaEl: HTMLTextAreaElement = this.textArea?.$el;
        if (textAreaEl == null) { return; }
        if (pos == null || posEnd == null) {
            const selection = this._getCursorPosition(textAreaEl);
            if (pos == null) {
                pos = selection.start;
            }
            if (posEnd == null) {
                posEnd = selection.end;
            }
        }
        if (model == null) {
            model = this._getModel();
        }
        const newModel = model.slice(0, pos) + '### ' + model.slice(pos);
        this._setModel(newModel);
        this._setFocusToPosition(pos + 4, posEnd + 4);
    }

    addBoldText(pos?: number, posEnd?: number, model?: string) {
        const textAreaEl: HTMLTextAreaElement = this.textArea?.$el;
        if (textAreaEl == null) { return; }
        if (pos == null || posEnd == null) {
            const selection = this._getCursorPosition(textAreaEl);
            if (pos == null) {
                pos = selection.start;
            }
            if (posEnd == null) {
                posEnd = selection.end;
            }
        }
        if (model == null) {
            model = this._getModel();
        }
        const newModel = model.slice(0, pos) + '**' + model.slice(pos, posEnd) + '**' + model.slice(posEnd);
        this._setModel(newModel);
        this._setFocusToPosition(pos + 2, posEnd + 2);
    }

    addItalicText(pos?: number, posEnd?: number, model?: string) {
        const textAreaEl: HTMLTextAreaElement = this.textArea?.$el;
        if (textAreaEl == null) { return; }
        if (pos == null || posEnd == null) {
            const selection = this._getCursorPosition(textAreaEl);
            if (pos == null) {
                pos = selection.start;
            }
            if (posEnd == null) {
                posEnd = selection.end;
            }
        }
        if (model == null) {
            model = this._getModel();
        }
        const newModel = model.slice(0, pos) + '_' + model.slice(pos, posEnd) + '_' + model.slice(posEnd);
        this._setModel(newModel);
        this._setFocusToPosition(pos + 1, posEnd + 1);
    }

    addQuate(pos?: number, posEnd?: number, model?: string) {
        const textAreaEl: HTMLTextAreaElement = this.textArea?.$el;
        if (textAreaEl == null) { return; }
        if (pos == null || posEnd == null) {
            const selection = this._getCursorPosition(textAreaEl);
            if (pos == null) {
                pos = selection.start;
            }
            if (posEnd == null) {
                posEnd = selection.end;
            }
        }
        if (model == null) {
            model = this._getModel();
        }
        const newModel = model.slice(0, pos) + '> ' + model.slice(pos);
        this._setModel(newModel);
        this._setFocusToPosition(pos + 2, posEnd + 2);
    }

    addCode(pos?: number, posEnd?: number, model?: string) {
        const textAreaEl: HTMLTextAreaElement = this.textArea?.$el;
        if (textAreaEl == null) { return; }
        if (pos == null || posEnd == null) {
            const selection = this._getCursorPosition(textAreaEl);
            if (pos == null) {
                pos = selection.start;
            }
            if (posEnd == null) {
                posEnd = selection.end;
            }
        }
        if (model == null) {
            model = this._getModel();
        }
        const newModel = model.slice(0, pos) + '`' + model.slice(pos, posEnd) + '`' + model.slice(posEnd);
        this._setModel(newModel);
        this._setFocusToPosition(pos + 1, posEnd + 1);
    }

    addLink(pos?: number, posEnd?: number, model?: string) {
        const textAreaEl: HTMLTextAreaElement = this.textArea?.$el;
        if (textAreaEl == null) { return; }
        if (pos == null || posEnd == null) {
            const selection = this._getCursorPosition(textAreaEl);
            if (pos == null) {
                pos = selection.start;
            }
            if (posEnd == null) {
                posEnd = selection.end;
            }
        }
        if (model == null) {
            model = this._getModel();
        }
        const newModel = model.slice(0, pos) + '[' + model.slice(pos, posEnd) + '](url)' + model.slice(posEnd);
        this._setModel(newModel);
        if (pos == posEnd) {
            this._setFocusToPosition(pos + 1, posEnd + 1);
        } else {
            this._setFocusToPosition(posEnd + 3, posEnd + 6);
        }
    }

    addBulletedList(pos?: number, posEnd?: number, model?: string) {
        const textAreaEl: HTMLTextAreaElement = this.textArea?.$el;
        if (textAreaEl == null) { return; }
        if (pos == null || posEnd == null) {
            const selection = this._getCursorPosition(textAreaEl);
            if (pos == null) {
                pos = selection.start;
            }
            if (posEnd == null) {
                posEnd = selection.end;
            }
        }
        if (model == null) {
            model = this._getModel();
        }
        // const newModel = model.slice(0, pos) + '- ' + model.slice(pos);
        const newModel = this._insertTextAfterLastSubstring(model.slice(0, pos), '\n', '- ') + model.slice(pos, posEnd).split('\n').join('\n- ') + model.slice(posEnd);
        this._setModel(newModel);
        this._setFocusToPosition(pos + 2, posEnd + model.slice(pos, posEnd).split('\n').length*2);
    }

    addNumberedList(pos?: number, posEnd?: number, model?: string) {
        const textAreaEl: HTMLTextAreaElement = this.textArea?.$el;
        if (textAreaEl == null) { return; }
        if (pos == null || posEnd == null) {
            const selection = this._getCursorPosition(textAreaEl);
            if (pos == null) {
                pos = selection.start;
            }
            if (posEnd == null) {
                posEnd = selection.end;
            }
        }
        if (model == null) {
            model = this._getModel();
        }
        const newModel = model.slice(0, pos) + '1. ' + model.slice(pos);
        this._setModel(newModel);
        this._setFocusToPosition(pos + 2, posEnd + 2);
    }

    addAttachment(attachmentInfo: AttachmentInfo, pos?: number, posEnd?: number, model?: string) {
        const textAreaEl: HTMLTextAreaElement = this.textArea?.$el;
        if (textAreaEl == null) { return; }
        if (pos == null || posEnd == null) {
            const selection = this._getCursorPosition(textAreaEl);
            if (pos == null) {
                pos = selection.start;
            }
            if (posEnd == null) {
                posEnd = selection.end;
            }
        }
        if (model == null) {
            model = this._getModel();
        }
        const isImage = fileUtils.isImage(attachmentInfo.FileName);
        const fileRefQueryParam = attachmentInfo.FileRef ? `?${attachmentInfo.FileRef}` : '';
        const newModel = isImage 
            ? model.slice(0, pos) + ` ![${attachmentInfo.FileName}](/api/file/view/${this.props.attachmentsDataObject.viewName}/${attachmentInfo.PrimKey}${fileRefQueryParam}) ` + model.slice(posEnd)
            : model.slice(0, pos) + ` [${attachmentInfo.FileName}](/api/file/download/${this.props.attachmentsDataObject.viewName}/${attachmentInfo.PrimKey}${fileRefQueryParam}) ` + model.slice(posEnd);
        this._setModel(newModel);
        if (pos == posEnd) {
            this._setFocusToPosition(pos + 1, posEnd + 1);
        } else {
            this._setFocusToPosition(posEnd + 3, posEnd + 6);
        }
    }

    async setupTextArea(textArea: RichTextArea | null) {
        if (textArea != null && this.textArea == null) {
            this.textArea = textArea;
            if (this.textArea.$el == null) {
                await nextTick();
            }
            if (this.props.attachmentsDataObject) {
                this.textArea.$el.addEventListener('paste', (pEvent: ClipboardEvent) => {
                    if (pEvent.clipboardData?.files.length) {
                        const files: File[] = [];
                        pEvent.preventDefault();
                        for (let i = 0; i < pEvent.clipboardData.files.length; i++) {
                            const file = pEvent.clipboardData.files[i];
                            if (file.name === 'image.png') {
                                const date = new Date();
                                const dateString = `${date.getUTCFullYear()}-${date.getUTCMonth()}-${date.getUTCDate()}T${date.getUTCHours()}.${date.getUTCMinutes()}.${date.getUTCSeconds()}`;
                                files.push(new File([file], `image-${dateString}.png`, { type: file.type, lastModified: file.lastModified }));
                            } else {
                                files.push(file)
                            }
                        }
                        if (files.length) {
                            this.isUploading = true;
                            this.cancelEditorCloseFromBlur();
                            this.props.attachmentsDataObject.fileUpload.upload({
                                files: files
                            }).then(data => {
                                this.isUploading = false;
                                return nextTick().then(() => {
                                    data.forEach(attachment => {
                                        this.addAttachment(attachment)
                                    });
                                });
                            }).finally(() => {
                                this.isUploading = false;
                            });
                        }
                    };
                });
            }
            this.textArea.$el.addEventListener('keydown', (e: KeyboardEvent) => {
                const target: HTMLTextAreaElement | null = e.target as HTMLTextAreaElement;
                if (target == null) { return; }
                if (e.ctrlKey) {
                    switch (e.key) {
                        case 'b':
                        case 'B':
                            e.preventDefault();
                            this.addBoldText();
                            return;
                        case 'i':
                        case 'I':
                            e.preventDefault();
                            this.addItalicText();
                            return;
                        case 'e':
                        case 'E':
                            e.preventDefault();
                            this.addCode();
                            return;
                        case 'k':
                        case 'K':
                            e.preventDefault();
                            this.addLink();

                    }
                } else {
                    if (target.selectionStart !== target.selectionEnd) { return; }
                    switch (e.key) {
                        case 'Enter':
                            const model = this._getModel();
                            const currentRow = model.slice(0, target.selectionStart).split('\n').at(-1);
                            if (currentRow == null) { return; }
                            if (currentRow.startsWith('- ')) {
                                console.log('enter event')
                                const newModel = model.slice(0, target.selectionStart) + '\n' + model.slice(target.selectionStart);
                                this.addBulletedList(target.selectionStart+1, target.selectionEnd+1, newModel);
                                e.preventDefault();
                            }
                            break;
                    }
                }
            });
        } else if (textArea == null) {
            this.textArea = null;
        }
    }

    handleEditorFocus(_e: FocusEvent) {
        if (this._blurDebounce) {
            window.clearTimeout(this._blurDebounce);
            this._blurDebounce = null;
        }
    }

    handleEditorBlur(_e: FocusEvent) {
        if (this._blurDebounce) { window.clearTimeout(this._blurDebounce); }
        if (this.isUploading) { return; }
        this._blurDebounce = window.setTimeout(() => {
            this.editMode = false;
            this._blurDebounce = null;
        }, 100);
    }

    cancelEditorCloseFromBlur() {
        if (this._blurDebounce) { window.clearTimeout(this._blurDebounce); }
    }

    handleAttachmentUploaded(pData: AttachmentInfo[]) {
        pData.forEach(attachment => {
            this.addAttachment(attachment)
        });
    }

    handlePreviewClick(e: MouseEvent) {
        const target = e.target as HTMLElement;
        if (target == null) { return; }
        switch (target.tagName) {
            case 'A':
                return;
            default:
                e.preventDefault();
                this.enterEditMode();
        }
    }

    async enterEditMode() {
        this.editMode = true;
        await nextTick();
        this.textArea?.$el?.focus();
    }

    exitEditMode() {
        this.editMode = false;
    }

    focus() {
        if (this.textArea?.$el) {
            const position = this._getCursorPosition(this.textArea?.$el);
            this._setFocusToPosition(position.start, position.end);
        }
    }

    private _getCursorPosition(textarea: HTMLInputElement | HTMLTextAreaElement): { start: number, end: number } {
        let startPos = 0;
        let endPos = 0;

        if ('selectionStart' in textarea && 'selectionEnd' in textarea) {
            startPos = textarea.selectionStart ?? 0;
            endPos = textarea.selectionEnd ?? startPos;
        } else if ('selection' in document) {
            // @ts-ignore
            textarea.focus();
            // @ts-ignore
            const sel = document.selection.createRange();
            // @ts-ignore
            const selLength = document.selection.createRange().text.length;
            // @ts-ignore
            sel.moveStart('character', -textarea.value.length);
            startPos = sel.text.length - selLength;
            endPos = startPos + selLength;
        }

        return { start: startPos, end: endPos };
    }


    private _setFocusToPosition(pos: number, posEnd?: number) {
        nextTick().then(() => {
            this.textArea?.$el?.focus();
            this.textArea?.$el?.setSelectionRange(pos, posEnd ?? pos);
        });
        // if (this._focuusDebounce != null) { window.clearTimeout(this._focuusDebounce); }
        // this._focuusDebounce = window.setTimeout(() => {
        //     this._focuusDebounce = null;
        //     nextTick().then(() => {
        //         this.textArea?.$el?.focus();
        //         this.textArea?.$el?.setSelectionRange(pos, posEnd ?? pos);
        //     });
        // }, 50);
    }

    /** Helper for inserting text after last instace of substring*/
    private _insertTextAfterLastSubstring(str: string, substring: string, text: string) {
        const parts = str.split(substring);
        const lastPartIndex = parts.length - 1;

        if (lastPartIndex > 0 || str.indexOf(substring) === -1) {
            parts[lastPartIndex] = text + parts[lastPartIndex];
        }

        return parts.join(substring);
    }
}